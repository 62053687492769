.methods-text-container {
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 236px);
}

.methods-text-subcontainer {
	margin-bottom: 30px;
}
.methods-text-subcontainer > h4 {
	font-weight: bold;
}

.methods-text-subcontainer > h5 {
	width: fit-content;
	border-bottom: 1px solid #2b2b2b;
	margin-top: 15px;
}

.methods-text-subcontainer > .search-terms-container {
	font-size: 0.8rem !important;
	color: var(--lightblack) !important;
	margin-left: 30px;
}
.method-bottom {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
@media only screen and (max-width: 767.5px) {
	.method-bottom {
		width: 50%;
	}
}
