@font-face {
	font-family: "Century Gothic Bold";
	src: url("../fonts/CenturyGothicBold.ttf") format("truetype");
}
@media screen and (min-width: 990px) {
	#desktop-footer {
		visibility: visible;
		display: block;
		margin-top: 50px !important;
	}

	#mobile-footer {
		visibility: hidden;
		display: none;
	}

	.footer-container {
		width: 100%;
		height: auto;
		background-color: var(--primary-color);
		color: white !important;
	}

	.footer-container-wrapper {
		display: flex;
		padding: 75px 15px !important;
		margin: auto;
		/* padding: 75px 0px; */
		/* padding: 75px 20px; */
		height: auto;
	}

	.footer-container-left-section {
		width: 25%;
		margin-right: auto;
		text-align: left;
	}

	.footer-container-left-section img {
		/* width: 100%; */
		height: 80px;
		/* margin-right: auto;
	display: inline-block; */
	}

	.footer-container-middle-section {
		display: block;
		width: 50%;
		font-weight: 300;
		/* text-align: left; */
		/* margin-left: 20px; */
		font-size: 12px;
		/* text-align: left !important; */
		text-align: center;
	}

	.footer-container-middle-section a {
		color: white !important;
		margin: 0px 7px;
	}

	.footer-container-right-section {
		width: 25%;
		margin-left: auto;
		text-align: right;
	}

	.footer-container-right-section img {
		width: 100%;
		max-width: 250px;
		/* height: 80px; */
		/* margin-right: auto;
	display: inline-block; */
	}
}
@media (max-width: 332px) {
	.footer-container-text-section a {
		font-size: 11px !important;
	}
}
@media (max-width: 370px) {
	.footer-container-text-section a {
		margin: 0 2px !important;
	}
}
@media screen and (max-width: 990px) {
	#desktop-footer {
		visibility: hidden;
		display: none;
	}

	#mobile-footer {
		visibility: visible;
		display: block;
		margin-top: 50px !important;
	}

	.footer-container {
		width: 100%;
		height: auto;
		background-color: var(--primary-color);
		color: white !important;
		font-weight: 300;
		text-align: center;
		padding-top: 15px !important;
		padding-bottom: 5px !important;
	}

	.footer-container-logos-section {
		width: 100%;
		display: flex;
	}

	#footer-evidence-map-logo {
		margin-right: auto;
		width: 40%;
	}

	#footer-alma-economics-logo {
		margin-left: auto;
		width: 40%;
	}

	.footer-container-text-section {
		/* display: block; */
		width: 100%;
		/* display: flex; */
		margin-top: 30px;
		font-size: 14px;
		text-align: center !important;
		line-height: 20px;
		color: white !important;
	}

	.footer-container-text-section a {
		color: white !important;
		margin: 0px 5px;
	}
}

@media screen and (max-width: 550px) {
	#alma-logo-wide {
		visibility: hidden;
		display: none;
	}

	#alma-logo {
		visibility: visible;
		display: block;
	}

	#line-wide {
		visibility: hidden;
		display: none;
	}

	#line {
		visibility: visible;
		display: block;
	}

	#line-text {
		display: none !important;
	}
}

@media screen and (min-width: 550px) {
	#alma-logo-wide {
		visibility: visible;
		display: block;
	}

	#alma-logo {
		visibility: hidden;
		display: none;
	}

	#line-wide {
		visibility: visible;
		display: block;
	}

	#line {
		visibility: hidden;
		display: none;
	}
}
