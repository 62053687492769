.header-container {
	margin-top: 1rem;
	margin-bottom: 3rem;
	padding: 0px 17px !important;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
}

.header-left-section {
	display: flex;
	align-items: center;
	width: auto;
	margin: 0;
}

.header-right-section {
	display: flex;
	align-items: center;
	width: auto;
	margin: 0;
	gap: 20px;
	flex-wrap: wrap;
	font-family: "Century Gothic Bold";
}

.header-left-section > * {
	/* margin: 0 !important; */
	margin-right: auto;
}

.header-right-section > * {
	margin-left: auto;
	color: #28525e;
}

.header-right-section a:hover {
	color: #28525e;
}

@media screen and (min-width: 2px) {
	#break-header-title {
		display: none;
	}
}

/* @media screen and (max-width: 991px) {
	#break-header-title {
		display: flex;
	}
} */
/* @media screen and (max-width: 991px) {
	.header-container {
		flex-direction: column;
		align-items: ;
	}
} */
@media screen and (max-width: 575px) {
	.break {
		flex-basis: 100%;
		height: 0;
	}

	.header-right-section > * {
		margin-left: 0px;
		text-align: left !important;
	}
	.header-right-section {
		width: 100%;
	}
	#break-header-title {
		display: none;
	}
}

@media screen and (max-width: 375px) {
	#break-header-title {
		display: flex;
	}
}
