body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: Helvetica;
}

code {
	font-family: Helvetica;
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

th {
	font-weight: bold !important;
}

.report-link:hover {
	text-decoration: underline;
	color: initial;
}

:root {
	--cell-color: 116, 124, 132;
	--gray1: #ededed;
	--gray2: #dedede;
	--gray3: #d3d3d3;
	--gray4: #969696;
	--gray5: #6d6e71;
	--darkgray: #3b3b3c;
	--lightblack: #231f20;
}

:root {
	--circles-color: #f5d752;
	--primary-color: #6d8faa;
	--secondary-color: #6d8faa;
	/* --secondary-color = --primary-color + "cc */
	--hover-color: #6d8faa;
}

#evidence_map_logo {
	width: 325px !important;
}

#divider {
	height: 1px;
	background-color: #2b2b2b;
	border-top: none;
}

.notes-icon {
	width: 28px;
	display: block;
	margin-top: 2px;
}

.subpages-container {
	width: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

.subpage-button-container {
	width: auto;
	display: flex;
	font-size: 14px;
}

.subpage-button-text {
	display: flex;
	padding-left: 10px;
	padding-top: 8px;
	white-space: nowrap;
}

.subpage-button-container:hover {
	color: inherit !important;
	text-decoration: none !important;
}

@media (min-width: 990px) {
	.reports_sidebar {
		height: 100vh;
	}
}

@media (max-width: 600px) {
	.subpages-container {
		flex-wrap: wrap;
	}
}

#back_to_map_button,
#back_to_map_button:focus {
	background-color: #2b2b2b;
	border: #2b2b2b;
	box-shadow: none !important;
	color: white;
	height: 2.5rem;
	border-radius: 0%;
}

#back_to_map_button:hover {
	background-color: #aa1111;
}

#evidence_map_logo {
	width: 325px !important;
}

#divider {
	height: 1px;
	background-color: var(--primary-color);
	border-top: none;
}

.reports-report-title,
.similar-reports-label {
	font-weight: bold;
	color: #2b2b2b;
}

.similar-reports-label {
	font-size: 1.1rem;
}

.similar-reports-list {
	list-style: none;
	padding: 0%;
	height: 100%;
}

.reports-citation {
	color: var(--gray5);
	font-size: 14px;
	font-weight: 600;
}

.reports-abstract-label {
	color: var(--secondary-color);
	font-weight: bold;
}

.reports-abstract-label p {
	font-weight: 300;
}

#report {
	margin-top: 35px;
}

#report-link {
	color: var(--gray4);
	text-decoration: underline;
}

#report-link:hover {
	color: var(--secondary-color);
}
.abstract {
	white-space: pre-line !important;
}
#back_to_map_button,
#back_to_map_button:focus {
	background-color: #2b2b2b;
	border: #2b2b2b;
	color: white;
	height: 2.5rem;
	border-radius: 0%;
}

#back_to_map_button:hover {
	background-color: var(--hover-color);
	color: white;
}

.modal-content {
	border-radius: 0%;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.modal-body ul {
	list-style-type: none;
}

.modal-open {
	overflow: hidden;
	padding-right: 17px !important;
}
#mobile {
	display: none;
}

@media (min-width: 576px) {
	.modal-open {
		padding-right: 17px !important;
	}
}

.em-title,
.em-title:hover {
	color: #28525e;
	line-height: 0px !important;
	text-decoration: none;
	font-family: "Century Gothic Bold";
	letter-spacing: -1px;
}

.MuiOutlinedInput-root {
	border-radius: 0px !important;
}

.search {
	width: 100%;
	position: relative;
	display: flex;
}

.custom-link {
	text-decoration: underline;
}

#how_to_use:hover,
.custom-link:hover {
	color: var(--hover-color);
}

.upload-excel-container {
	font-size: 11px !important;
	margin-bottom: 10px !important;
}

body {
	padding-bottom: 60px;
}

.bottom-client-logo-container {
	display: flex;
	margin-left: auto;
}

.bottom-client-logo-container a {
	display: flex;
	width: 260px;
	height: 80px;
	margin-left: auto;
}

.bottom-client-logo-container img {
	width: 100%;
	height: 100%;
}

#email-modal-button-submit {
	background-color: #231f20;
	color: white;
	width: 100px;
	padding: 5px 0px;
	border: none;
}
.email-input-modal {
	display: block;
	margin-top: 200px;
}
@media only screen and (max-height: 600px) {
	.email-input-modal {
		margin-top: 0 !important;
	}
}
@media only screen and (max-width: 500px) {
	.email-input-modal {
		margin-top: 0 !important;
	}
}

.report-bottom {
	display: flex;
	width: 100%;
	max-width: calc(100% - 319.2px);
	justify-content: space-between;
}

.logo-description {
	margin-left: auto;
	text-align: right;
}

@media only screen and (min-width: 995px) {
	.bottom #logo-description-mobile {
		display: none;
	}
	.bottom #logo-description-desktop {
		display: flex;
	}
}

@media only screen and (max-width: 995px) {
	#bottom #logo-description-mobile {
		display: flex;
		text-align: left;
	}
	#bottom #logo-description-desktop {
		display: none;
	}

	#bottom .bottom-client-logo-container {
		flex-direction: column;
		margin-right: auto;
		margin-left: 0px;
	}
	#bottom .bottom-client-logo-container > a {
		margin-right: auto;
		margin-left: 0px;
	}
}

#description-break {
	display: block;
}

@media only screen and (min-width: 995px) {
	#bottom #logo-description-mobile {
		display: none;
	}
	#bottom #logo-description-desktop {
		display: flex;
	}
}

@media only screen and (max-width: 650px) {
	.report-bottom {
		flex-direction: column;
	}
	.report-bottom a {
		flex: 0 !important;
	}
	#description-break {
		display: none;
	}
	.report-bottom .bottom-client-logo-container {
		margin-left: 0px !important;
		margin-right: auto !important;
	}
	.report-bottom .bottom-client-logo-container p {
		text-align: left !important;
	}
	.report-bottom .bottom-client-logo-container a {
		margin-left: 0px !important;
		margin-right: auto !important;
	}
}
