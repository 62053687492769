.filters-container {
	display: flex;
	width: 100%;
	/* justify-content: space-between; */
	flex-wrap: wrap;
}

.filters-container > * {
	margin: 4px 4px 10px 4px !important;
	/* Add spacing between items */
}
#primary-label {
	font-size: x-large;
	display: flex;
	justify-content: center;
}
.primary-filters-container > .filters-container > * {
	width: 24%;
	min-width: 105px !important;
}

.advanced-filters-container > .filters-container > * {
	flex-basis: calc(33.33% - 10px);
}

.primary-filters-container > .filters-container > *:not(:last-child) {
	margin-right: 20px;
}

.ghost_button {
	padding: 0px !important;
	margin: 0px !important;
	height: 0px !important;
}

#filters_clear_button,
#filters_clear_button:focus {
	background-color: #2b2b2b;
	border: #2b2b2b;
	color: white;
	height: 2.5rem;
	border-radius: 0%;
	margin-left: auto;
}

#filters_clear_button:hover {
	background-color: var(--hover-color);
	color: white;
}

.clear-filters-container {
	margin-left: auto;
}

@media only screen and (min-width: 576px) {
	.search-bar {
		width: 50% !important;
	}
}

@media only screen and (max-width: 767px) {
	.search-bar {
		width: 100% !important;
	}
}

@media only screen and (max-width: 1000px) {
	.primary-filters-container > .filters-container > *:not(:last-child) {
		width: 31.82%;
	}

	.primary-filters-container > .filters-container > {
		justify-content: space-between;
	}

	#filters_clear_button {
		width: 100%;
		margin-top: 20px;
	}

	#ghost_container {
		display: none;
	}

	@media only screen and (min-width: 576px) {
		.primary-filters-container > .filters-container > *:nth-last-child(3),
		.primary-filters-container > .filters-container > *:nth-last-child(4) {
			width: calc(50% - 10px);
		}
	}

	@media only screen and (max-width: 575px) {
		.primary-filters-container > .filters-container > *:not(:last-child) {
			width: 48%;
		}
	}
	@media only screen and (max-width: 429px) {
		.primary-filters-container > .filters-container > *:not(:last-child) {
			width: 100% !important;
		}

		#view-selection-container {
			width: 100% !important;
		}

		#view-horizontal-scrolling {
			width: 100% !important;
			margin-top: 20px;
		}
	}
}

.MuiAccordionSummary-root {
	min-height: 40px !important;
}

.MuiAccordionSummary-content {
	margin: 0px !important;
}

.advanced-filters-container {
	padding: 0 !important;
	height: 200px !important;
}
.advanced-filters-container > .filters-container > #filters_clear_button {
	border: 1px solid #1d1d1d !important;
	background-color: white;
	color: #2b2b2b !important;
}

.container > .MuiAccordion-root {
	box-shadow: none !important;
	border: 1px solid #c4c4c4;
	border-radius: 0px !important;
	margin: 0px 4px !important;
}

.search-bar-container {
	display: flex !important;
	justify-content: left;
	margin-top: 15px;
	margin-bottom: 5px;
	padding-left: 20px !important;
}

.search-bar-container > * > * > * {
	border-radius: 6px !important;
}
.table-top-container {
	display: flex;
	justify-content: space-between;
	margin: 20px 0px 15px;
	flex-wrap: wrap;
	margin-left: 4px;
	margin-right: 4px;
}

#view-selection-container {
	width: 370px;
}

#view-horizontal-scrolling {
	min-width: 170px;
}

@media (max-width: 1200px) {
	#view-selection-container {
		width: calc(50% - 6px);
	}

	#view-horizontal-scrolling {
		width: calc(50% - 6px);
	}
}
.bubble-chart-legend-container {
	width: 460px;
	height: 45px !important;
	display: flex;
	flex-direction: column;
	height: auto;
	font-size: 12px;
}
.bubble-chart-legend-container {
	width: 460px;
	height: 45px !important;
	display: flex;
	flex-direction: column;
	/* padding-left: 30px; */
	margin-top: -10px;
	height: auto;
	font-size: 12px;
}

.bubble-chart-legend {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	height: calc(100% - 5px - 15px);
	flex-wrap: wrap;
}

.legend-item {
	display: flex;
	width: auto;
	margin: 5px;
}

.legend-colorBox {
	width: 1rem;
	height: 1rem;
	-moz-border-radius: 0.7rem;
	-webkit-border-radius: 0.7rem;
	border-radius: 0.7rem;
	margin-right: 10px;
}

@media (max-width: 991px) {
	.bubble-chart-legend-container {
		padding-left: 0px;
		margin-top: 10px;
	}
}
/* ----------- 991 - 1200px ----------- */
@media screen and (min-width: 991px) and (max-width: 1200px) {
	.bubble-chart-legend-container {
		padding-left: 0px;
		margin-top: 10px;
	}
}

@media (max-width: 575.5px) {
	.bubble-chart-legend-container {
		margin-bottom: 10px;
	}
}
@media (max-width: 381.5px) {
	.bubble-chart-legend-container {
		margin-bottom: 20px;
	}
}
#line-text {
	font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 0px 10px 10px;
}

#line-text a:link,
#line-text a:visited {
	text-decoration: inherit;
	color: inherit;
}
