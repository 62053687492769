.table {
	word-wrap: break-word !important;
	table-layout: fixed;
	width: 100%;
	border-spacing: 0;
	border-collapse: inherit;
	margin-bottom: 30px !important;
}

.table-container {
	margin-bottom: 15px;
}

.evidence-map-x-heading {
	line-height: 1.7 !important;
}

.evidence-map-y-heading {
	line-height: 1 !important;
}

.evidence-map-y-heading-categories {
	line-height: 1.1 !important;
}
.horizontal {
	overflow: scroll !important;
	max-height: 80vh;
	height: auto;
}
.horizontal-table {
	table-layout: auto !important;
	margin-bottom: 0px !important;
}
.horizontal-thead {
	overflow-x: hidden !important;
}
.horizontal-x-heading {
	position: absolute !important;
	width: 100%;
}
.horizontal-y-heading {
	position: absolute;
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	top: 68px;
}

.horizontal-y-heading-categories {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	top: 68px;
}
.horizontal-top-left-white {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	top: 0;
}

@media (min-width: 250px) {
	.evidence-map-x-heading {
		width: calc(400px - 170px - 30px);
	}
}
@media (min-width: 370px) {
	.evidence-map-x-heading {
		width: calc(396px - 170px - 30px);
	}
}
@media (min-width: 450px) {
	.evidence-map-x-heading {
		width: auto + 30px;
	}
}
@media (min-width: 576px) {
	.evidence-map-x-heading {
		width: calc(540px - 175px - 30px);
	}
}
@media (min-width: 768px) {
	.evidence-map-x-heading {
		width: calc(720px - 175px - 47px);
	}
}
@media (min-width: 992px) {
	.evidence-map-x-heading {
		width: calc(960px - 175px - 47px);
	}
}
@media (min-width: 1200px) {
	.evidence-map-x-heading {
		width: calc(1200px - 175px - 47px);
	}
}

@media (max-width: 767px) {
	.evidence-map-x-heading,
	.evidence-map-y-heading {
		font-size: 3vw !important;
	}
}
@media (max-width: 450px) {
	.evidence-map-x-heading,
	.evidence-map-y-heading {
		font-size: 4vw !important;
	}
}

.evidence-map-top-left-white {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	top: 0;
	border: none;
	text-align: center;
	padding: 0%;
	height: 68px;
	z-index: 10;
	background-color: white;
	/* border: 4px solid white !important; */
}

.evidence-map-x-heading {
	background-color: white;
	height: 68px;
	color: white !important;
	background-color: #28525e;
	color: white !important;
	font-size: 1.6rem;
	font-weight: bold !important;
	text-align: center;
}

.evidence-map-y-heading {
	font-size: 1.6rem;
	width: 25%;
	font-weight: bold;
	color: white;
	background-color: #28525e;
	height: 68px;
}

.evidence-map-x-heading-categories {
	height: 63px;
	vertical-align: middle !important;
	background-color: var(--gray2);
	font-size: 14px;
	font-weight: 900;
	text-align: center;
}

.evidence-map-y-heading-categories {
	background-color: var(--secondary-color);
	color: white;
	height: 55px;
	font-size: 16px;
	font-weight: 400;
	vertical-align: middle !important;
	font-weight: 600;
}

.evidence-map-y-heading > * > * {
	color: white !important;
	font-size: 1.6rem !important;
	font-weight: bold !important;
}

.evidence-map-y-heading
	> *
	> *
	> .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 0px !important;
	white-space: normal !important;
}

.evidence-map-x-heading > * > * > .MuiSvgIcon-root,
.evidence-map-y-heading > * > * > .MuiSvgIcon-root {
	color: white;
}

.evidence-map-y-heading > * > * > .MuiSvgIcon-root {
	padding-left: -10px !important;
}

.evidence-map-y-heading > * > * > .MuiOutlinedInput-notchedOutline {
	border: none !important;
}
/* .evidence-map-x-heading .evidence-map-x-heading-categories {
	z-index: 100;
} */
.em-cell-circle {
	/* Main table - grid - circle perimeter */
	display: flex;
	border-radius: 50%;
	font-weight: 700;
	font-size: 16px;
	line-height: 13px;
	text-align: center;
	cursor: pointer;
	padding: 8px 0;
	height: 100%;
	margin: auto;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(109, 143, 170, 0.3) -2px 2px 4px;
	color: #2b2b2b;
	transition: color 0.3s ease;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: scale(00.1) translateY(100%);
		z-index: -100000; /*For a bug if u refresh the page while on bottom of table the bubbles go above the headings untill animation is finished*/
	}

	100% {
		opacity: 1;
		transform: scale(1);
		z-index: 0; /*Reset index when animation finish */
	}
}

.em-cell-circle:hover {
	color: white;
}

.Positive {
	background-color: #90ee90;
	animation: fadeIn 1s linear;
}

.Negative {
	background-color: #f08080;
	animation: fadeIn 1.1s linear;
}

.Mixed {
	background-color: #93b0c7;
	animation: fadeIn 1.2s linear;
}
.Others {
	background-color: #d3d3d3;
	animation: fadeIn 1.3s linear;
}
.NR {
	background-color: #fff5c9;
	animation: fadeIn 1.4s linear;
}

.report-links {
	color: var(--darkgray);
}

.report-links:active,
.report-links:visited,
.report-links:focus {
	color: var(--darkgray);
	text-decoration: none;
}

/* everywhere - hyperlinks to reports */
.report-links:hover {
	color: var(--secondary-color);
	text-decoration: underline;
}

.table td:not(:first-child) {
	cursor: pointer;
}

.table th {
	vertical-align: middle !important;
}

.no-results-container {
	display: flex;
	min-height: 100px;
	height: calc(100vh - 806px);
	justify-content: center;
	align-items: center;
}

/* @media (max-width: 445px) {
	table {
		width: auto !important;
	}
	thead tr th:first-child,
	tbody tr td:first-child {
		width: 100px !important;
		min-width: 100px !important;
		max-width: 100px !important;
	}
	thead tr th:last-child,
	tbody tr td:last-child {
		width: 75px;
		min-width: 75px;
		max-width: 75px;
	}
	thead tr th:not(:first-child):not(:last-child),
	tbody tr td:not(:first-child):not(:last-child) {
		width: 175px;
		min-width: 175px;
		max-width: 175px;
	}
}
@media (max-width: 370px) {
	thead tr th:not(:first-child):not(:last-child),
	tbody tr td:not(:first-child):not(:last-child) {
		width: 150px;
		min-width: 150px;
		max-width: 150px;
	}
}
@media (max-width: 332px) {
	thead tr th:not(:first-child):not(:last-child),
	tbody tr td:not(:first-child):not(:last-child) {
		width: 130px;
		min-width: 130px;
		max-width: 130px;
	}
}
@media (max-width: 315px) {
	thead tr th:not(:first-child):not(:last-child),
	tbody tr td:not(:first-child):not(:last-child) {
		width: 120px;
		min-width: 120px;
		max-width: 120px;
	}
} */
/* @media (max-width: 445px) {
	thead tr th:first-child,
	tbody tr td:first-child {
		width: 100px !important;
		min-width: 100px !important;
		max-width: 100px !important;
	}
	thead tr th:last-child,
	tbody tr td:last-child {
		width: 75px;
		min-width: 75px;
		max-width: 75px;
	}
	thead tr th:not(:first-child):not(:last-child),
	tbody tr td:not(:first-child):not(:last-child) {
		width: 170px;
		min-width: 170px;
		max-width: 170px;
	}
} */
thead tr th:first-child,
tbody tr td:first-child {
	width: 175px;
	min-width: 175px;
	max-width: 175px;
}

tbody td:not(:first-child) {
	min-width: 200px !important;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 68px; /*86*/
	z-index: 10;
	box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.7);
	-moz-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.7);
	-webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.7);
}

.phoneSticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 10;
	box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.7);
	-moz-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.7);
	-webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.7);
}

.borders {
	border: 1px solid #d3d3d3 !important;
}

tbody tr td:last-child {
	border-right: 2px solid #d3d3d3 !important;
}

.total-reports-cell {
	color: #696969;
	font-weight: 900;
	text-align: center;
	background-color: #f1f1f1;
}
