.spinner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px 0px;
	height: 600px;
}

.lds-grid {
	display: inline-block;
	position: relative;
	width: 70px;
	height: 70px;
}
.lds-grid div {
	position: absolute;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #3b4559;
	animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1),
.lds-grid div:nth-child(2),
.lds-grid div:nth-child(12),
.lds-grid div:nth-child(16) {
	visibility: hidden;
}

.lds-grid div:nth-child(3) {
	left: 36px;
	top: 0px;
	animation-delay: -0.4s;
}

.lds-grid div:nth-child(4) {
	left: 54px;
	top: 0px;
	animation-delay: -0.6s;
}

.lds-grid div:nth-child(5) {
	left: 0px;
	top: 18px;
	animation-delay: -0.2s;
}

.lds-grid div:nth-child(6) {
	left: 18px;
	top: 18px;
	animation-delay: -0.4s;
}
.lds-grid div:nth-child(7) {
	left: 36px;
	top: 18px;

	animation-delay: -0.6s;
}
.lds-grid div:nth-child(8) {
	left: 54px;
	top: 18px;
	animation-delay: -0.8s;
}

.lds-grid div:nth-child(9) {
	left: 0px;
	top: 36px;
	animation-delay: -0.4s;
}
.lds-grid div:nth-child(10) {
	left: 18px;
	top: 36px;
	background: #fcec04;
	animation-delay: -0.6s;
}
.lds-grid div:nth-child(11) {
	left: 36px;
	top: 36px;
	animation-delay: -0.8s;
}

.lds-grid div:nth-child(13) {
	left: 0px;
	top: 54px;
	animation-delay: -0.6s;
}
.lds-grid div:nth-child(14) {
	left: 18px;
	top: 54px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(15) {
	left: 36px;
	top: 54px;
	animation-delay: -1s;
}

@keyframes lds-grid {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}
